// @import url("https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap");
$black: #000;
$black-bis: #222636;
$black-ter: #383b4d;

$grey-darker: #999999;
$grey-dark: #8b8d8f; //#8e8e93;
$grey: #eeeeee;
$grey-1: #cccccc;
$grey-2: #cfcfd3;
$grey-3: #d1d1d1;
$grey-4: #8e8e93;
$grey-light: #f9f9f9;
$grey-light-slight: #f5f5f5;
$grey-lighter: #f1f1f1;

$blue-lightest: #efeff4; // lightest
$blue: #0d1121;
$blue-light: #3d414d;
$blue-ter: #13284c;

$red-dark: #ff0042; //#d40000;
$red: #ff0042;
$red-light: #f6cccc;

$orange: #d57367;
$yellow: #fbf051;
$green: #86dd64; //00c6c0;
$turquoise: #00c6c0;
$cyan: #428dff;
$purple: #694c93;

$times-color: #808080;

$family-sans-serif: 'Roboto', sans-serif;
$family-monospace: monospace;
$render-mode: optimizeLegibility;

// Responsiveness

// The container horizontal gap, which acts as the offset for breakpoints
$gap: 32px;
//smallest device this base on iphone 5 SE
$smallDevice: 321px;
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$all-device: 1px;
$tablet: 761px;
// 960px container + 4rem
$desktop: 960px + (2 * $gap);
// 1152px container + 4rem
$widescreen: 1152px + (2 * $gap);
$widescreen-enabled: true;
// 1344px container + 4rem
$fullhd: 1344px + (2 * $gap);
$fullhd-enabled: false;

// base 16px
$size-1: 3rem; // 48px
$size-2: 2.375rem; //38px
$size-3-3: 2.125rem; //34px
$size-3: 1.875rem; //30px
$size-4-4: 1.5rem; // 24px
$size-4: 1.375rem; // 22px
$size-5-5: 1.25rem; //20px
$size-5: 1.125rem; //18px
$size-6: 1rem; //16px
$size-7: 0.875rem; // 14px
$size-8: 0.75rem; // 12px
$size-9: 0.625rem; // 10px

$weight-light: 300;
$weight-normal: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;
$weight-black: 900;
