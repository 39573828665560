@import 'styles/all_vars';

.tag-list {
  padding: 50px 0;
}

.tag-list__item {
  text-decoration: none !important;
  text-transform: uppercase;
  padding: 1rem;
  font-weight: 600;

  &:hover {
    color: $red;
  }
}

.tag-list__item--active {
  text-decoration: underline !important;
}

.tags-group {
  margin-top: 10px;
}
